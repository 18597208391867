import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';
import { ReservationMessage } from '../../models';

export const getUnreadReceivedMessagesCount = (state: State) =>
  state.unreadReceivedMessagesCount;

export const getAllParticipants = (state: State) => {
  return state?.participants;
};

export const getMessagesStatistics = (state: State) => {
  return state?.statistics;
};

export const getUnreadMessages = (messages: ReservationMessage[]) =>
  messages?.filter(
    ({ read_at, sender_participant: { type } }) =>
      !read_at && type !== 'property',
  );

export const selectState = createFeatureSelector<State>(
  'reservationDetailsMessages',
);

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectUnreadMessages = createSelector(
  selectAllItems,
  getUnreadMessages,
);

export const selectAllParticipants = createSelector(
  selectState,
  getAllParticipants,
);

export const selectMessageStatistics = createSelector(
  selectState,
  getMessagesStatistics,
);

export const selectError = createSelector(selectState, (state) => state.error);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectAllParticipantsWithoutProperty = createSelector(
  selectAllParticipants,
  (participants) => {
    return participants?.filter(
      ({ related_type }) => related_type !== 'property',
    );
  },
);

export const selectUsedParticipantsWithoutProperty = createSelector(
  selectAllParticipants,
  (participants) => {
    return participants?.filter(
      ({ related_type, used }) => related_type !== 'property' && used,
    );
  },
);

export const selectPagination = createSelector(
  selectState,
  (state) => state.pagination,
);

export const selectMessagesIdsToCheck = createSelector(
  selectAllItems,
  (messages) =>
    messages
      .filter(
        ({
          delivery_status,
          source_type,
          reply_needed,
          sender_participant: { type },
        }) =>
          (delivery_status !== 'displayed' &&
            delivery_status !== 'failed' &&
            source_type !== 'whatsapp' &&
            type === 'property') ||
          reply_needed === true,
      )
      .map(({ id }) => id),
);

export const selectUnreadReceivedMessagesCount = createSelector(
  selectState,
  getUnreadReceivedMessagesCount,
);
