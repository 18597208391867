import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import {
  CheckMessageReadStatusResponse,
  CheckMessageStatusRequest,
  CreateExtraEmailRequest,
  CreateExtraPhoneRequest,
  DeleteMessageRequest,
  ExtraEmail,
  GetConversationRequest,
  LoadReservationMessageParticipantsRequest,
  LoadReservationMessagesRequest,
  MessageReplyNeededRequest,
  ResendReservationMessageRequest,
  ReservationMessage,
  ReservationMessageParticipant,
  ReservationMessageUpdated,
  SendReservationMessageRequest,
  SetMessagesReadStatusRequest,
} from '../../models';
import { ReservationMessagesStatistic } from '../../models/objects/reservation-messages-statistics';
import { Hooks } from '../../helpers';

export const getUnreadReceivedMessagesCountRequest = createAction(
  '[Reservation Details Message] Get Unread Recived Messages Count Request',
  props<{ data: GetConversationRequest }>(),
);

export const getUnreadReceivedMessagesCountSuccess = createAction(
  '[Reservation Details Message] Get Unread Recived Messages Count Success',
  props<{ count: number }>(),
);

export const getUnreadReceivedMessagesCountFailure = createAction(
  '[Reservation Details Message] Get Unread Recived Messages Count Failure',
  props<{ error: any }>(),
);

export const setMessagesReadStatusRequest = createAction(
  '[Reservation Details Message] Set Messages Read Status Request',
  props<{ data: SetMessagesReadStatusRequest; hooks?: Hooks }>(),
);

export const setMessagesReadStatusSuccess = createAction(
  '[Reservation Details Message] Set Messages Read Status Success',
  props<{ items: CheckMessageReadStatusResponse[] }>(),
);

export const setMessagesReadStatusFailure = createAction(
  '[Reservation Details Message] Set Messages Read status Failure',
  props<{ error: any }>(),
);

export const loadMoreMessagesRequest = createAction(
  '[Reservation Details Message] Load More Messages Request',
  props<{ data: LoadReservationMessagesRequest }>(),
);

export const loadMoreMessagesSuccess = createAction(
  '[Reservation Details Message] Load More Messages Success',
  props<{ items: ReservationMessage[]; pagination: IPagination }>(),
);

export const loadMoreMessageFailure = createAction(
  '[Reservation Details Message] Load More Message Failure',
  props<{ error: any }>(),
);

export const loadMessagesRequest = createAction(
  '[Reservation Details Message] Load  Messages Request',
  props<{ data: LoadReservationMessagesRequest; hooks?: Hooks }>(),
);

export const loadMessagesSuccess = createAction(
  '[Reservation Details Message] Load  Messages Success',
  props<{ items: ReservationMessage[]; pagination: IPagination }>(),
);

export const loadMessageFailure = createAction(
  '[Reservation Details Message] Load  Message Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Reservation Details Message] Delete Request',
  props<{ data: DeleteMessageRequest }>(),
);

export const deleteSuccess = createAction(
  '[Reservation Details Message] Delete Success',
  props<{ id: number }>(),
);

export const deleteFailure = createAction(
  '[Reservation Details Message] Delete Failure',
  props<{ error: any }>(),
);

export const replyNeededRequest = createAction(
  '[Reservation Details Message] Reply Needed Request',
  props<{ data: MessageReplyNeededRequest }>(),
);

export const replyNeededSuccess = createAction(
  '[Reservation Details Message] Reply Needed Success',
);

export const replyNeededFailure = createAction(
  '[Reservation Details Message] Reply Needed Failure',
  props<{ error: any }>(),
);

export const searchRequest = createAction(
  '[Reservation Details Message] Search Request',
  props<{ data: LoadReservationMessagesRequest }>(),
);

export const searchSuccess = createAction(
  '[Reservation Details Message] Search Success',
  props<{ items: ReservationMessage[]; pagination: IPagination }>(),
);

export const searchFailure = createAction(
  '[Reservation Details Message] Search Failure',
  props<{ error: any }>(),
);

export const loadParticipantsRequest = createAction(
  '[Reservation Details Message] Load Participants Request',
  props<{ data: LoadReservationMessageParticipantsRequest }>(),
);

export const loadParticipantsSuccess = createAction(
  '[Reservation Details Message] Load Participants Success',
  props<{ items: ReservationMessageParticipant[] }>(),
);

export const loadParticipantsFailure = createAction(
  '[Reservation Details Message] Load Participants Failure',
  props<{ error: any }>(),
);

export const loadMessageStatisticsRequest = createAction(
  '[Reservation Details Message Statistics Request',
  props<{ reservationId: number }>(),
);

export const loadMessageStatisticsSuccess = createAction(
  '[Reservation Details Message Statistics Success',
  props<{ statistics: ReservationMessagesStatistic[] }>(),
);

export const loadMessageStatisticsFailure = createAction(
  '[Reservation Details Message Statistics Failure',
  props<{ error: any }>(),
);

export const sendRequest = createAction(
  '[Reservation Details Message] Send Request',
  props<{
    data: SendReservationMessageRequest;
    onSuccess?: (data: IResponseSuccess<ReservationMessage[]>) => void;
  }>(),
);

export const sendSuccess = createAction(
  '[Reservation Details Message] Send Success',
  props<{ items: ReservationMessage[] }>(),
);

export const sendFailure = createAction(
  '[Reservation Details Message] Send Failure',
  props<{ error: any }>(),
);

export const resendRequest = createAction(
  '[Reservation Details Message] Resend Request',
  props<{ data: ResendReservationMessageRequest; onSuccess?: () => void }>(),
);

export const resendSuccess = createAction(
  '[Reservation Details Message] Resend Success',
  props<{ item: ReservationMessage }>(),
);

export const resendFailure = createAction(
  '[Reservation Details Message] Resend Failure',
  props<{ error: any }>(),
);

export const chekMessagesStatusRequest = createAction(
  '[Reservation Details Message] Chack Messages Status Request',
  props<{ data: CheckMessageStatusRequest }>(),
);

export const chekMessagesStatusSuccess = createAction(
  '[Reservation Details Message] Chack Messages Status Success',
  props<{
    items: ReservationMessageUpdated[];
  }>(),
);

export const chekMessagesStatusFailure = createAction(
  '[Reservation Details Message] Chack Messages Status Failure',
  props<{ error: any }>(),
);

export const createExtraEmailRequest = createAction(
  '[Reservation Details Message] Create Extra Email Request',
  props<{
    data: CreateExtraEmailRequest;
    onSuccess: () => void;
    onFailure: () => void;
  }>(),
);

export const createExtraEmailSuccess = createAction(
  '[Reservation Details Message] Create Extra Email Success',
  props<{
    item: ExtraEmail;
  }>(),
);

export const createExtraEmailFailure = createAction(
  '[Reservation Details Message] Create Extra Email Failure',
  props<{ error: any }>(),
);

export const createExtraPhoneRequest = createAction(
  '[Reservation Details Message] Create Extra Phone Request',
  props<{
    data: CreateExtraPhoneRequest;
    onSuccess: () => void;
    onFailure: () => void;
  }>(),
);

export const createExtraPhoneSuccess = createAction(
  '[Reservation Details Message] Create Extra Phone Success',
  props<{
    item: ReservationMessageParticipant;
  }>(),
);

export const createExtraPhoneFailure = createAction(
  '[Reservation Details Message] Create Extra Phone Failure',
  props<{ error: any }>(),
);

export const addParticipant = createAction(
  '[Reservation Details Message] add participant',
  props<{ participant: ReservationMessageParticipant }>(),
);

export const resetState = createAction(
  '[Reservation Details Message] Reset State',
);
