import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IPagination } from '../../core/models/api/pagination/pagination.model';
import {
  ReservationMessage,
  ReservationMessageParticipant,
} from '../../models';
import { ReservationMessagesStatistic } from '../../models/objects/reservation-messages-statistics';

export const featureAdapter: EntityAdapter<ReservationMessage> =
  createEntityAdapter<ReservationMessage>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<ReservationMessage> {
  isLoading?: boolean;
  error?: any;
  pagination: IPagination;
  participants: ReservationMessageParticipant[];
  statistics: ReservationMessagesStatistic[];
  unreadReceivedMessagesCount: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  pagination: null,
  participants: null,
  statistics: null,
  unreadReceivedMessagesCount: null,
});
