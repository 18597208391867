import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { MessageFormatService } from '../../services/message-format.service';

import * as fromActions from './actions';

@Injectable()
export class MessageFormatStoreEffects {
  constructor(
    private dataService: MessageFormatService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap((data) => {
        const { onComplete } = data;
        return this.dataService.formatText(data).pipe(
          map((text: string) => {
            if (onComplete) {
              onComplete();
            }
            return fromActions.loadSuccess({ text });
          }),
          catchError((error) => {
            if (onComplete) {
              onComplete();
            }
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}
