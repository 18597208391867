import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { effectHooks } from '../../helpers';

import { ExtraEmailService, ReservationMessagesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ReservationDetailsStoreEffects {
  loadingMessagesMap = {
    loading: 'loading_messages',
    sending: 'sending_in_progress',
    editing_message: 'editing_message',
  };

  messageId: string;

  constructor(
    private dataService: ReservationMessagesService,
    private extraEmailService: ExtraEmailService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private message: NzMessageService,
    private translate: TranslateService,
    private notifications: NotificationService,
  ) {}

  loadMoreMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMoreMessagesRequest),
      switchMap(({ data }) => {
        this.message.remove(this.messageId);

        this.messageId = this.message.loading(
          upperFirst(this.translate.instant(this.loadingMessagesMap.loading)),
          {
            nzDuration: 0,
          },
        ).messageId;

        return this.dataService.load(data).pipe(
          map((response) => {
            this.message.remove(this.messageId);

            return fromActions.loadMoreMessagesSuccess({
              items: response.data,
              pagination: response.meta.pagination,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            this.message.remove(this.messageId);

            return of(fromActions.loadMoreMessageFailure(error));
          }),
        );
      }),
    ),
  );

  loadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMessagesRequest),
      switchMap(({ data, hooks }) => {
        this.message.remove(this.messageId);

        this.messageId = this.message.loading(
          upperFirst(this.translate.instant(this.loadingMessagesMap.loading)),
          {
            nzDuration: 0,
          },
        ).messageId;

        return this.dataService.load(data).pipe(
          effectHooks(hooks),
          map((response) => {
            this.message.remove(this.messageId);

            return fromActions.loadMessagesSuccess({
              items: response.data,
              pagination: response.meta.pagination,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            this.message.remove(this.messageId);

            return of(fromActions.loadMessageFailure(error));
          }),
        );
      }),
    ),
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.searchRequest),
      switchMap(({ data }) => {
        this.message.remove(this.messageId);

        this.messageId = this.message.loading(
          upperFirst(this.translate.instant(this.loadingMessagesMap.loading)),
          {
            nzDuration: 0,
          },
        ).messageId;

        return this.dataService.load(data).pipe(
          map((response) => {
            this.message.remove(this.messageId);

            return fromActions.searchSuccess({
              items: response.data,
              pagination: response.meta.pagination,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            this.message.remove(this.messageId);

            return of(fromActions.searchFailure(error));
          }),
        );
      }),
    ),
  );

  loadMessageStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMessageStatisticsRequest),
      switchMap(({ reservationId }) =>
        this.dataService.loadStatistics(reservationId).pipe(
          map(({ data: statistics }) =>
            fromActions.loadMessageStatisticsSuccess({ statistics }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadMessageStatisticsFailure(error));
          }),
        ),
      ),
    ),
  );

  resend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendRequest),
      switchMap(({ data, onSuccess }) => {
        this.message.remove(this.messageId);

        this.messageId = this.message.loading(
          upperFirst(this.translate.instant(this.loadingMessagesMap.sending)),
          {
            nzDuration: 0,
          },
        ).messageId;

        return this.dataService.resend(data).pipe(
          effectHooks({ onSuccess }),
          map((response) => {
            this.message.remove(this.messageId);

            return fromActions.resendSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            this.message.remove(this.messageId);

            return of(fromActions.resendFailure(error));
          }),
        );
      }),
    ),
  );

  send$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendRequest),
      switchMap(({ data, onSuccess }) => {
        this.message.remove(this.messageId);

        this.messageId = this.message.loading(
          upperFirst(this.translate.instant(this.loadingMessagesMap.sending)),
          {
            nzDuration: 0,
          },
        ).messageId;

        return this.dataService.send(data).pipe(
          effectHooks({ onSuccess }),
          map((response) => {
            this.message.remove(this.messageId);

            return fromActions.sendSuccess({
              items: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            this.message.remove(this.messageId);

            return of(fromActions.sendFailure(error));
          }),
        );
      }),
    ),
  );

  loadGuests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadParticipantsRequest),
      switchMap(({ data }) => {
        return this.dataService.loadParticipants(data).pipe(
          map((response) => {
            return fromActions.loadParticipantsSuccess({
              items: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.loadParticipantsFailure(error));
          }),
        );
      }),
    ),
  );

  deleteMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ data }) => {
        return this.dataService.deleteMessage(data).pipe(
          map(() => {
            return fromActions.deleteSuccess({
              id: data.id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.deleteFailure(error));
          }),
        );
      }),
    ),
  );

  replyNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.replyNeededRequest),
      switchMap(({ data }) => {
        return this.dataService.replyNeeded(data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.generic_success'),
              type: 'success',
            });
            return fromActions.replyNeededSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.replyNeededFailure(error));
          }),
        );
      }),
    ),
  );

  checkMessageStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.chekMessagesStatusRequest),
      switchMap(({ data }) => {
        return this.dataService.checkStatus(data).pipe(
          map((response) => {
            return fromActions.chekMessagesStatusSuccess({
              items: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.chekMessagesStatusFailure(error));
          }),
        );
      }),
    ),
  );

  setMessagesReadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setMessagesReadStatusRequest),
      switchMap(({ data, hooks }) => {
        this.message.remove(this.messageId);

        this.messageId = this.message.loading(
          upperFirst(
            this.translate.instant(this.loadingMessagesMap.editing_message),
          ),
          {
            nzDuration: 0,
          },
        ).messageId;

        return this.dataService.setMessagesReadStatus(data).pipe(
          effectHooks(hooks),
          switchMap((response) => {
            this.message.remove(this.messageId);

            const { conversable_id, conversable_type } = data;

            return [
              fromActions.setMessagesReadStatusSuccess({
                items: response.data,
              }),
              fromActions.getUnreadReceivedMessagesCountRequest({
                data: {
                  conversable_id,
                  conversable_type,
                },
              }),
            ];
          }),
          catchError((error) => {
            this.message.remove(this.messageId);

            this.errorHandler.handle(error);

            return of(fromActions.setMessagesReadStatusFailure(error));
          }),
        );
      }),
    ),
  );

  getUnreadReceivedMessagesCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUnreadReceivedMessagesCountRequest),
      switchMap(({ data }) => {
        return this.dataService.getUnreadReceivedMessagesCount(data).pipe(
          map((response) => {
            return fromActions.getUnreadReceivedMessagesCountSuccess({
              count: response.data.count,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.setMessagesReadStatusFailure(error));
          }),
        );
      }),
    ),
  );

  createExtraEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createExtraEmailRequest),
      switchMap(({ data, onSuccess, onFailure }) => {
        return this.extraEmailService.createExtraEmail(data).pipe(
          effectHooks({ onSuccess, onFailure }),
          map((response) => {
            return fromActions.createExtraEmailSuccess({
              item: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.createExtraEmailFailure(error));
          }),
        );
      }),
    ),
  );

  createExtraPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createExtraPhoneRequest),
      switchMap(({ data, onSuccess, onFailure }) => {
        return this.dataService.createExtraPhone(data).pipe(
          effectHooks({ onSuccess, onFailure }),
          map((response) => {
            return fromActions.createExtraPhoneSuccess({
              item: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.createExtraPhoneFailure(error));
          }),
        );
      }),
    ),
  );
}
