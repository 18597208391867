import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.getUnreadReceivedMessagesCountFailure, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.getUnreadReceivedMessagesCountSuccess, (state, { count }) => {
    return {
      ...state,
      unreadReceivedMessagesCount: count,
    };
  }),
  on(fromActions.getUnreadReceivedMessagesCountFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadMoreMessagesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.loadMoreMessagesSuccess, (state, { items, pagination }) => {
    const itemsToAdd = [
      ...[...items].reverse(),
      ...state?.ids.map((id) => state.entities?.[id]),
    ];

    return fromState.featureAdapter.setAll(itemsToAdd, {
      ...state,
      isLoading: false,
      error: null,
      pagination,
    });
  }),

  on(fromActions.loadMoreMessageFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadMessagesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.loadMessagesSuccess, (state, { items, pagination }) => {
    const orderedItems = [
      ...[...items].reverse(),
      ...state?.ids.map((id) => state.entities?.[id]),
    ];

    return fromState.featureAdapter.setAll(orderedItems, {
      ...state,
      isLoading: false,
      error: null,
      pagination,
    });
  }),

  on(fromActions.loadMessageFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { id }) => {
    return fromState.featureAdapter.removeOne(id, {
      ...state,
      isLoading: false,
      error: null,
    });
  }),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.searchRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.searchSuccess, (state, { items, pagination }) => {
    return fromState.featureAdapter.setAll([...items].reverse(), {
      ...state,
      isLoading: false,
      error: null,
      pagination,
    });
  }),
  on(fromActions.searchFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadParticipantsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadParticipantsSuccess, (state, { items }) => {
    return {
      ...state,
      participants: [...items],
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadParticipantsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadMessageStatisticsRequest, (state) => ({
    ...state,
    error: null,
  })),

  on(fromActions.loadMessageStatisticsSuccess, (state, { statistics }) => ({
    ...state,
    statistics,
    error: null,
  })),

  on(fromActions.loadMessageStatisticsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(fromActions.sendRequest, (state) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(fromActions.sendSuccess, (state, { items }) => {
    return fromState.featureAdapter.addMany(items, {
      ...state,
      isLoading: false,
      error: null,
    });
  }),

  on(fromActions.sendFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.setMessagesReadStatusRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.setMessagesReadStatusSuccess, (state, { items }) => {
    return fromState.featureAdapter.updateMany(
      items.map((item) => ({ changes: item, id: item.id })),
      {
        ...state,
        isLoading: false,
        error: null,
      },
    );
  }),
  on(fromActions.setMessagesReadStatusFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resendRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.resendSuccess, (state, { item }) => {
    return fromState.featureAdapter.upsertOne(item, {
      ...state,
      isLoading: false,
      error: null,
    });
  }),
  on(fromActions.resendFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.chekMessagesStatusSuccess, (state, { items }) => {
    const data = items.map((item) => ({ id: item.id, changes: item }));

    return fromState.featureAdapter.updateMany(data, {
      ...state,
      error: null,
    });
  }),

  on(fromActions.chekMessagesStatusFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.addParticipant, (state, { participant }) => ({
    ...state,
    isLoading: false,
    participants: [...state.participants, participant],
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function reservationDetailsMessagesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
