import { createAction, props } from '@ngrx/store';

import { MessageGatewayType } from '../../models';

export const loadRequest = createAction(
  '[Message Format] Load Request',
  props<{
    text: string;
    gateway: MessageGatewayType;
    onComplete?: () => void;
  }>(),
);

export const loadSuccess = createAction(
  '[Message Format] Load Success',
  props<{ text: string }>(),
);

export const loadFailure = createAction(
  '[Message Format] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Message Format] Reset State');
